import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { MDXRenderer } from "gatsby-plugin-mdx";
import React from "react";
import Layout from "../../components/layout";

const BlogPost = ({ data }) => {
  const image = getImage(data.mdx.frontmatter.hero_image);
  return (
    <Layout pageTitle={data.mdx.frontmatter.name}>
      <p>Published: <i>{data.mdx.frontmatter.date}</i></p>
      <GatsbyImage image={image} alt={data.mdx.frontmatter.hero_image_alt} title={data.mdx.frontmatter.hero_image_alt} />
      <p style={{textAlign: "center"}}>
        Photo Credit:{" "}<a href={data.mdx.frontmatter.hero_image_credit_link}>{data.mdx.frontmatter.hero_image_credit_text}</a>
      </p>
      <MDXRenderer>{data.mdx.body}</MDXRenderer>
      <Link to="/blog">Back to post list</Link>
    </Layout>
  );
}

export const query = graphql`
  query ($id: String) {
    mdx(id: {eq: $id}) {
      body
      frontmatter {
        date(formatString: "MMMM D, YYYY")
        name
        hero_image_alt
        hero_image_credit_link
        hero_image_credit_text
        hero_image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      id
    }
  }
`;

export default BlogPost;
